
import {
    CloudUploadOutlined
} from '@ant-design/icons-vue';
import {
    utils,
    read
} from 'xlsx';
export default {
    components: {
        CloudUploadOutlined
    },
    inject: ['packages', 'test_rates', "form"],
    emits: [
        'done',
        'update:visible'
    ],
    props: {
        // 是否打开弹窗
        visible: Boolean,
        data: Object
    },
    data() {
        return {
            // 导入请求状态
            loading: false,
            message_type: 'error',
            messages: []
        };
    },
    methods: {
        /* 上传 */
        doUpload(file) {
            this.messages = [];
            this.loading = true;

            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = read(data, {
                    type: 'array'
                });
                const sheetNames = workbook.SheetNames;
                const worksheet = workbook.Sheets[sheetNames[0]];
                // 解析成二维数组
                const aoa = utils.sheet_to_json(worksheet, {
                    header: 1
                });
                const result = aoa.filter((i, index) => {
                    let pass = true;
                    for (let j = 0; j < i.length; j++) {
                        if (!i[j]) {
                            pass = false;
                            break;
                        }
                    }

                    return pass && i.length > 0 && index > 0
                }).map((i, index) => {
                    if ((typeof i[5] != 'string' || !['in', 'cm'].includes(i[5].toLocaleLowerCase()))) {
                        this.messages.push(`第<${index + 1}>行数据，填写数据格式不正确`)
                    }
                    if ((typeof i[5] != 'string' || !['lbs', 'kgs'].includes(i[7].toLocaleLowerCase()))) {
                        this.messages.push(`第<${index + 1}>行数据，填写数据格式不正确`)
                    }

                    return {
                        id: this.$util.uuid(),
                        freight_class: i[0].toString(),
                        packages_qty: i[1],
                        pieces: i[1],
                        length: i[2],
                        width: i[3],
                        height: i[4],
                        dims_unit: i[5].toLocaleLowerCase(),
                        weight: i[6],
                        weight_unit: i[7].toLocaleLowerCase(),
                        reference1: this.form.value.global_reference1 || '',
                        reference2: this.form.value.global_reference2 || '',
                        unit: {
                            dims_unit: i[5].toLocaleLowerCase(),
                            weight_unit: i[7].toLocaleLowerCase(),
                        }
                    }
                })

                if (!this.messages.length) {
                    this.packages.value = this.packages.value.filter(i => i.weight).concat(result);
                    this.test_rates();
                    this.updateVisible(false);
                }

                this.loading = false
            };
            reader.readAsArrayBuffer(file);
            return false;
        },
        /* 更新visible */
        updateVisible(value) {
            this.$emit('update:visible', value);
        }
    }
}
